<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>INFORMASI POHON </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputInformasi">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="judul_informasi_pohon"
                  label="Judul Informasi Pohon"
                  placeholder="Judul Informasi Pohon"
                />
              </CCol>

              <CCol sm="12">
                <CTextarea
                  v-model="isi_informasi_pohon"
                  label="Isi Informasi Pohon"
                  placeholder="Isi Informasi Pohon"
                />  
              </CCol>           
              
              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Informasi </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>

            </CRow>          
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/informasi">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  data () {
    return {
      judul_informasi_pohon : "",
      isi_informasi_pohon : "",
      foto_informasi_text : "",
      foto_informasi_pohon : "",
      placeholder : "Pilih File",
      selected : [], // Must be an array reference!
      show: true,
      modalError: false,
    }
  },
  methods: {

    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.foto_informasi_text = fileData.name;
      this.foto_informasi_pohon = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },
    
    inputInformasi: function(event){
      
      const formData = new FormData()
      
      // formData.append('id_informasi_pohon', this.$route.params.id_informasi_pohon)
      formData.append('judul_informasi_pohon', this.judul_informasi_pohon)
      formData.append('isi_informasi_pohon', this.isi_informasi_pohon)

      if (this.foto_informasi_text) {
          // alert(this.foto_informasi_pohon);
        formData.append('foto_informasi_pohon', this.foto_informasi_pohon, this.foto_informasi_pohon.name)      
        formData.append('foto_informasi_text', this.foto_informasi_pohon.name)
      }
      
      axios
        .post(
          process.env.VUE_APP_BASE_URL+"informasipohon", formData
        )
        .then((response) => {
            // console.log(response);
            if(response.data.status=='success'){
              this.$router.push('/informasi');
            }
            else{
              this.modalError = true;
            }          
        })
    }
  }
}
</script>